export const dashboardRoutes = [
    {
        path: "/auth/login",
        name: "Login",
        component: () => import("../views/dashboard/views/login/Index.vue"),
        meta: { requiresNoAuth: true, title: "Login" },
    },
    {
        path: "/dashboard/",
        name: "dashoard",
        component: () => import("../views/dashboard/layout/Sidebar.vue"),
        children: [
            {
                path: "",
                name: "dashboard",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "dashboard",
                },
                component: () => import("../views/dashboard/views/homepage/Index.vue"),
            },
            {
                path: "categories",
                name: "categories",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "categories",
                },
                component: () =>
                    import("../views/dashboard/views/categories/Index.vue"),
            },
            {
                path: "sub-categories",
                name: "subCategories",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "subCategories",
                },
                component: () =>
                    import("../views/dashboard/views/sub-categories/Index.vue"),
            },
            {
                path: "brands",
                name: "brands",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "brands",
                },
                component: () =>
                    import("../views/dashboard/views/brands/Index.vue"),
            },
            {
                path: "users",
                name: "users",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "users",
                },
                component: () =>
                    import("../views/dashboard/views/users/Index.vue"),
            },
            {
                path: "products",
                name: "products",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "products",
                },
                component: () =>
                    import("../views/dashboard/views/products/Index.vue"),
            },
            {
                path: "products/add",
                name: "products-add",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "products-add",
                },
                component: () =>
                    import("../views/dashboard/views/products/add/Index.vue"),
            },
            {
                path: "products/:id",
                name: "product",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "product",
                },
                component: () =>
                    import("../views/dashboard/views/products/add/Index.vue"),
            },
            {
                path: "blogs",
                name: "blogs",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "blogs",
                },
                component: () =>
                    import("../views/dashboard/views/blogs/Index.vue"),
            },
            {
                path: "blogs/add",
                name: "blog-add",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "blog-add",
                },
                component: () =>
                    import("../views/dashboard/views/blogs/add/Index.vue"),
            },
            {
                path: "blogs/:id",
                name: "blog",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "blog",
                },
                component: () =>
                    import("../views/dashboard/views/blogs/add/Index.vue"),
            },
            {
                path: "photos",
                name: "photos",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "photos",
                },
                component: () =>
                    import("../views/dashboard/views/photos/Index.vue"),
            },
            {
                path: "sliders",
                name: "sliders",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "sliders",
                },
                component: () =>
                    import("../views/dashboard/views/sliders/Index.vue"),
            },
            {
                path: "support-personnel",
                name: "support-personnel",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "support-personnel",
                },
                component: () =>
                    import("../views/dashboard/views/support-personnel/Index.vue"),
            },
            {
                path: "messages",
                name: "messages",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "messages",
                },
                component: () =>
                    import("../views/dashboard/views/messages/Index.vue"),
            },
            {
                path: "settings",
                name: "settings",
                meta: {
                    dashboard: true,
                    requiresAuth: true,
                    title: "settings",
                },
                component: () =>
                    import("../views/dashboard/views/settings/Index.vue"),
            },
        ],
    },
]