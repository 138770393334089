export const websiteRoutes = [
    {
        path: "/",
        name: "Home",
        component: () => import("../views/website/layout/Navbar"),
        children: [
            {
                path: "/",
                name: 'Homepage',
                component: () => import("../views/website/views/homepage/Index.vue"),
            },
            {
                path: "/products",
                name: 'Products',
                component: () => import("../views/website/views/products/Index.vue"),
            },
            {
                path: "/products/:id",
                name: 'Product-details',
                component: () => import("../views/website/views/products/id/Index.vue"),
            },
            {
                path: "/blogs",
                name: 'Blogs',
                component: () => import("../views/website/views/blogs/Index.vue"),
            },
            {
                path: "/blogs/:id",
                name: 'Blog',
                component: () => import("../views/website/views/blogs/id/Index.vue"),
            },
            {
                path: "/contact-us",
                name: 'Contact-us',
                component: () => import("../views/website/views/contact-us/Index.vue"),
            },
            {
                path: "/about-us",
                name: 'About-us',
                component: () => import("../views/website/views/about-us/Index.vue"),
            },
        ],
    },
]