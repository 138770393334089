import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/style/main.scss";
import "./assets/style/_variables.scss";
import "./assets/style/rtl.scss";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";

// components
import BaseSelect from "@/components/base/Select";
import BaseInput from "@/components/base/Input";
import BaseDate from "@/components/base/Date";
import BasePassword from "@/components/base/Password";
import BaseTextarea from "@/components/base/Textarea";
import UploadImage from "@/components/base/UploadImage";
import DeleteDialog from "@/components/dialogs/Delete";

// i18n
import i18n from "./i18n";
import axios from "./plugins/axios";

// slick carousel
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// map
import * as VueGoogleMaps from "vue2-google-maps";

import CKEditor from '@ckeditor/ckeditor5-vue2'

Vue.config.productionTip = false;
Vue.prototype.$showImage = (id) => {
  return id ? `https://api.blacksmith.com.tr/${id}` : 'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png';
};

Vue.use(VueGoogleMaps, {
  load: {
    key: "",
    libraries: "places", // This is required if you use the Autocomplete plugin
    installComponents: true,
  },
});


Vue.component("base-select", BaseSelect);
Vue.component("base-input", BaseInput);
Vue.component("base-date", BaseDate);
Vue.component("base-password", BasePassword);
Vue.component("base-textarea", BaseTextarea);
Vue.component("upload-image", UploadImage);
Vue.component("delete-dialog", DeleteDialog);
Vue.component("slick-slider", VueSlickCarousel);

Vue.use(axios);

Vue.mixin({
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isMd() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md
      );
    },
    isLg() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg
      );
    },
  },
});

Vue.use( CKEditor );

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

// change picker package
