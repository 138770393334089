<template>
  <div class="base-date base-input input-style" :style="{ width: width }">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          :placeholder="placeholder"
          dense
          v-bind="attrs"
          v-on="on"
          height="36px"
          max-height="36px"
          max
          :rules="rules"
          :hide-details="true"
          :value="value"
          @input="$emit('input', $event)"
        >
        </v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        scrollable
        :multiple="multiple"
        :range="range"
        :min="min"
        :max="max"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="okHandler"> OK </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Array],
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
  },
  data: () => ({
    date: null,
    menu: false,
  }),
  methods: {
    okHandler() {
      this.menu = false;
      this.$emit("valueHandler", this.date);
    },
  },
  created() {
    this.date = this.value;
  },
};
</script>

<style lang="scss">
.base-date {
  .v-input__append-inner {
    margin-top: 0px !important;
    margin-right: 10px !important;
  }
}
</style>
