<template>
  <v-app class="app" >
    <router-view :key="$route.name && languageCode"></router-view>
    <snackbar></snackbar>
    <loader v-if="loading"></loader>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Snackbar from "@/components/global/Snackbar";
import Loader from "@/components/global/Loader";

export default {
  name: "App",
  components: {
    Snackbar,
    Loader,
  },
  computed: {
    ...mapGetters(["loading", 'languageCode']),
    
  },
  methods: {},
  async mounted() {
  },
};
</script>

<style lang="scss">
.app {
  .v-application--wrap {
  }
}
</style>
