import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    text: "",
    color: "",
    timeout: "",
    loading: false,
    token: "",
    userKey: "",
    userDetails: {},
    languageCode: localStorage.getItem('language') || 'en',
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    getLayoutPage(state) {
      return state.layoutPage;
    },
    token(state) {
      return state.token;
    },
    userDetails(state) {
      return state.userDetails;
    },
    userKey(state) {
      return state.userKey;
    },
    languageCode(state){
      return state.languageCode
    },
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_TOKEN(state, payload) {
      localStorage.setItem("token", payload);
      state.token = payload;
    },
    SET_KEY(state, payload) {
      localStorage.setItem("key", payload);
      state.userKey = payload;
    },
    REMOVE_USER(state) {
      localStorage.removeItem("token");
      state.token = "";
      router.go("/auth/login");
    },
    SET_USER_DETAILS(state, payload) {
      state.userDetails = payload;
    },
    LANG_CHANGED(state, payload) {
      state.languageCode = payload;
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    logout({ commit }) {
      commit("REMOVE_USER");
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    setUserDetails({ commit }, payload) {
      commit("SET_USER_DETAILS", payload);
    },
    langChanged({ commit }, payload) {
      commit("LANG_CHANGED", payload);
    },
  },
  modules: {},
});
