import Vue from "vue";
import axios from "axios";
import store from "../store";
Vue.prototype.$baseURL = "https://api.blacksmith.com.tr/api/v1";

axios.defaults.baseURL = `${Vue.prototype.$baseURL}/`;

window.axios = axios;
Vue.prototype.$axios = axios;

axios.defaults.headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export default async () => {
  // show loader in each request
  window.axios.interceptors.request.use(
    (confiq) => {
      if (confiq.method != "get" && !confiq.params && confiq.url != "products/search") {
        store.dispatch("loading", true);
      }
      return confiq;
    },
    (error) => {
      return error;
    }
  );

  window.axios.interceptors.response.use(
    function (response) {
      store.dispatch("loading", false);
      return response;
    },
    async function (error) {
      store.dispatch("showSnack", {
        text: error.response.data.msg,
        color: "error",
      });
      // if (error.response.status == 401) {
      //   store.dispatch("logout");
      // }
      store.dispatch("loading", false);
      return error.response.data;
    }
  );
};
