<template>
  <div class="add-role">
    <div class="px-5 py-4 black">
      <span class="white--text font-16"
        >Are you sure you want to delete ?
      </span>
    </div>
    <div class="pa-3 px-5 white">
      <v-form  ref="delete">
        <div class="d-flex align-center justify-space-between my-3">
          <v-btn
            elevation="0"
            class="radius-5 px-10 py-1"
            @click="$emit('close')"
          >
            <span class="black--text font-600 font-16">Cancel</span>
          </v-btn>

          <v-btn
            color="error"
            elevation="0"
            class="radius-5 px-10 py-1"
            @click="$emit('deleteHandler')"
          >
            <span class="white--text font-600 font-16">Delete</span>
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    obj: {},
    valid: false,
  }),

  methods: {},
  created() {},
};
</script>

<style lang="scss">
.category-key {
  padding: 5px 15px;
  border-radius: 3px;
  background: #dbdbdb;

  &__delete {
    position: absolute !important;
    top: -4px;
    right: -4px;
  }
}
</style>
