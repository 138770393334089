import { dashboardRoutes } from "./dashboard";
import { websiteRoutes } from "./website";

const routes = [
  ...dashboardRoutes,
  ...websiteRoutes,
  {
    path: "*",
    redirect: "/auth/login",
  },
];

export default routes;
