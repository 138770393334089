export default {
    homepage: {
        services: {
            service_1_title: 'الحجز عبر الإنترنت',
            service_1_desc: 'اسطوانات هيدروليكية تلسكوبية',
            service_2_title: 'خيارات الخدمة',
            service_2_desc: 'مضخات هيدروليكية',
            service_3_title: 'تقدير التكاليف',
            service_3_desc: 'إقلاع الطاقة الهيدروليكية (P.T.O)',
            service_4_title: 'جدولة الخدمة',
            service_4_desc: 'الينابيع ورقة',
            service_5_title: 'آراء العملاء',
            service_5_desc: 'خزانات النفط والفول',
            service_6_title: 'العضوية والمكافآت',
            service_6_desc: 'جميع قطع غيار المقطورات',
        },

        happy_clients: 'عملاء سعداء',
        vehicle_tools: 'أدوات المركبة',
        diagnosis_accuracy: 'دقة التشخيص',
        learn_more: 'المزيد',
        view_products: 'عرض المنتجات',
        blog_articles: '- مدونة ومقالة',
        blog_articles_title: 'اقرأ أحدث مدونتنا ومقالاتنا',
        read_more: 'اقرأ المزيد',
        year: 'سنة',
        experience: 'خبرة',
        scroll: 'سحب',
        no_comments: 'لايوجد تعليق',

        quote: {
            title_1: 'أحلام القيادة،',
            title_2: 'واقع الخدمة.',
            desc: 'جدد رحلتك مع خصم 30% على خدمات السيارات!',
            get_quote: 'الحصول على الاقتباس +'
        },


        about: {
            about_details: '- حول التفاصيل',
            title: 'التميز في القيادة، خدمة واحدة في كل مرة',
            auto_services: 'خدمات السيارات التفصيلية',
            desc: 'Blacksmith شركة. بدأت نشاطها التجاري عام 2014 بتوفير وتصنيع قطع غيار المقطورات والشاحنات تحت العلامة التجارية (BSCO) بما في ذلك ما يلي:',
            auto_service_1:'الينابيع ورقة ',
            auto_service_2:'براميل الفرامل',
            auto_service_3:'محاور العجلات ',
            auto_service_4:'معدات الهبوط ',
            auto_service_5:'معدات ناقلة ',
            auto_service_6:'المحاور ومعدات المقطورة',
            desc2: 'منذ عام 2018، ركزت مؤسستنا جهودها على تطوير وإنتاج الأنظمة الهيدروليكية تحت علامتنا التجارية المسجلة (BSH).',
            desc3: 'في Blacksmith شركة, نحن متخصصون في الأنظمة الهيدروليكية ونقدم مجموعة شاملة من قطع الغيار للمقطورات والشاحنات. مع التركيز الشديد على الجودة والابتكار، يكرس فريقنا جهوده لتقديم منتجات رفيعة المستوى تلبي الاحتياجات المحددة لعملائنا. من الأسطوانات الهيدروليكية إلى قطع غيار المقطورات والشاحنات، نحن نفخر بتقديم حلول موثوقة تضمن الأداء الأمثل والمتانة. مهمتنا هي أن نكون المصدر المفضل للأنظمة الهيدروليكية عالية الجودة وقطع غيار الشاحنات، وتزويد عملائنا بالأدوات التي يحتاجونها للحفاظ على تشغيل مركباتهم بسلاسة. مرحبًا بكم في شركة بلاكسميث، حيث الجودة والموثوقية هي أهم أولوياتنا.'

        }
    },

    footer: {
        contact: 'تواصل',
        quick_links: 'روابط سريعة',
        open_hours: 'ساعات العمل',
        start_time: 'الاثنين - الجمعة',
        end_time: 'السبت',
        subscibe: 'اشترك في النشرة الإخبارية',
        home: 'الصفحة الرئيسية',
        about_us: 'معلومات عنا',
        products: 'منتجات',
        categories: 'الأصناف',
        sub_categories: 'أصناف جزئية',
        blogs: 'المدونات',
        contact_us: 'اتصل بنا',
        emergency_call: 'مكالمة طارئة',
        logo_title: 'Black Smith Company',
        footer_desc: '”التميز في قطع غيار الشاحنات، على الطريق وخارجه.”',
        copy_right_1: 'Copyright  ',
        copy_right_2: ' © Black Smith | Powered by <a href="https://smartart.agency/en/" target="_blank"> Smart Art </a>',
    },
    global: {
        search_for_product: 'ابحث عن المفضلة لديك',
        product: ':منتج',
        arabic: 'العربية',
        english: 'English',
        details_here: 'التفاصيل هنا'
    },
    form: {
        search_product: 'ابحث هنا...',
        email: 'البريد الإلكتروني',
        sub_categories: 'أصناف جزئية',
        phone: 'رقم الهاتف',
        title: 'العنوان',
        message: 'الرسالة',
        send_message: 'ارسل الرسالة',
        select_brand: 'اختر الماركة...',
        select_category: 'اختر الصنف...',
        select_sub_category: 'اختر الصنف الجزئي...',
        send: 'ارسال',
        checkbox: 'احفظ اسمي، بريدي الإلكتروني، والموقع الإلكتروني في هذا المتصفح لاستخدامها في المرة القادمة التي أقوم فيها بالتعليق.',
        comment: 'التعليق',
        search_by_code_or_name: 'بحث عن طريق الرمز او الاسم',
        search: 'بحث'
    },

    contact: {
        title: '- تواصل معنا',
        desc: 'لأي استفسار أو سؤال، لا تتردد في الاتصال بنا',
        location: 'الموقع'
    },

    product: {
        variations: 'الاختلافات',
        follow_us_on: 'تابعنا على',
        offer_desc: 'لن يتم نشر عنوان بريدك الإلكتروني. الحقول المطلوبة مشار إليها *',
        get_offer: 'احصل على عرض',
        item_code: 'رمز الصنف',
        brand: 'الماركة',
        category: 'الصنف',
        sub_category: 'الصنف الجزئي',
        views: 'عدد مرات الزيارة',
        download_title: 'قم بتنزيل وانظر إلى  ',
        download_btn: 'تنزيل الدليل بصيغة PDF',
        technicial_specifications: 'المواصفات الفنية'
    },

    messages: {
        required_fields: 'يرجى التحقق من الحقول المطلوبة',
    }
}