export default {
    homepage: {
        services: {
            service_1_title: 'ONLINE BOOKING',
            service_1_desc: 'Hydraulic Telescopic cylinders',
            service_2_title: 'SERVICE OPTIONS',
            service_2_desc: 'Hydraulic pumps',
            service_3_title: 'COST ESTIMATION',
            service_3_desc: 'Hydraulic Power Take Off (P.T.O)',
            service_4_title: 'SERVICE SCHEDULING',
            service_4_desc: 'Leaf Springs',
            service_5_title: 'CUSTOMER REVIEWS',
            service_5_desc: 'Oil and Fule Tanks',
            service_6_title: 'MEMBERSHIP & REWARDS',
            service_6_desc: 'All trailer Spare Parts',
        },

        happy_clients: 'Happy Clients',
        vehicle_tools: 'Vehicle Tools',
        diagnosis_accuracy: 'Diagnosis Accuracy',
        learn_more: 'Learn More',
        view_products: 'View Products',
        blog_articles: '- BLOG & ARTICLE',
        blog_articles_title: 'READ OUR LATEST BLOG & ARTICLES',
        read_more: 'READ MORE',
        year: 'YEAR',
        experience: 'EXPERIENCE',
        scroll: 'Scroll',
        no_comments: 'No Comments',



        quote: {
            title_1: 'DRIVING DREAMS,',
            title_2: 'SERVICING REALITY.',
            desc: 'Revitalize Your Ride with a 30% Discount on Auto Services!',
            get_quote: 'Get Quote +'
        },


        about: {
            about_details: '- ABOUT DETAIL',
            title: 'DRIVING EXCELLENCE, ONE SERVICE AT A TIME',
            desc: 'Blacksmith company. Began its commercial activities in 2014 to providing and manufacture spare parts for trailers and trucks under trademark (BSCO) including the following:',
            auto_services: 'DETAIL AUTO SERVICES',
            auto_service_1:'Leaf springs ',
            auto_service_2:'Brake drums',
            auto_service_3:'Wheel hubs ',
            auto_service_4:'Landing gears ',
            auto_service_5:'Tanker equipment ',
            auto_service_6:'Axles and trailer equipment',
            desc2: 'Since 2018, our organization has concentrated its efforts on the development and production of hydraulic systems under our registered trademark, (BSH).',
            desc3: 'At Blacksmith Company, we specialize in hydraulic systems and provide a comprehensive range of spare parts for trailers and trucks. With a keen focus on quality and innovation, our team is dedicated to delivering top-notch products that meet the specific needs of our customers. From hydraulic cylinders to trailer and truck spare parts, we take pride in offering reliable solutions that ensure optimal performance and durability. Our mission is to be the go-to source for high-quality hydraulic systems and truck spare parts, providing our customers with the tools they need to keep their vehicles running smoothly. Welcome to Blacksmith Company, where quality and reliability are our top priorities.            '
        }
    },

    footer: {
        contact: 'Contact',
        quick_links: 'QUICK LINKS',
        open_hours: 'Open Hours:',
        start_time: 'Mon - Fri',
        end_time: 'Sat',
        subscibe: 'SUBSCRIBE FOR NEWSLETTER',
        home: 'Home',
        about_us: 'About Us',
        categories: 'Categories',
        sub_categories: 'Sub Categories',
        products: 'Products',
        blogs: 'Blogs',
        contact_us: 'Contact Us',
        emergency_call: 'Emergency Call',
        logo_title: 'Black Smith Company',
        footer_desc: '”Truck Parts Excellence, On and Off the Road.”',
        copy_right_1: 'Copyright  ',
        copy_right_2: ' © Black Smith | Powered by <a href="https://smartart.agency/en/" target="_blank"> Smart Art </a>',
    },
    global: {
        search_for_product: 'Search for your favorite',
        product: 'Product',
        arabic: 'العربية',
        english: 'English',
        details_here: 'Details Here',
        tags: 'Tags',
    },

    form: {
        search_product: 'Search here...',
        email: 'Email',
        phone: 'Phone',
        title: 'Title',
        message: 'Message',
        send_message: 'SEND A MESSAGE',
        select_brand: 'Select Brand...',
        select_category: 'Select Category...',
        select_sub_category: 'Select Sub Category...',
        brands: 'Brands',
        categories: 'Categories',
        sub_categories: 'Sub Categories',
        send: 'SEND',
        checkbox: 'Save my name, email, and website in this browser for the next time I comment.',
        comment: 'Comment',
        search_by_code_or_name: 'Search by Code or Name',
        search: 'Search'
    },

    contact: {
        title: '- CONTACT US',
        desc: `For any inquiry or question, don't hesitate to contact us`,
        location: 'Location'
    },

    product: {
        variations: 'VARIATIONS',
        follow_us_on: 'FOLLOW US ON',
        offer_desc: 'Your email address will not be published. Required fields are marked *',
        get_offer: 'GET OFFER',
        item_code: 'Item Code',
        brand: 'Brand',
        category: 'Category',
        sub_category: 'Sub Category',
        views: 'Views',
        download_title: 'DOWNLOAD & LOOK THE ',
        download_btn: ' DOWNLOAD MANUAL PDF',
        technicial_specifications: 'Technical Specifications'
    },

    messages: {
        required_fields: 'Please check required fields',
    }
}