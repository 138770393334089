import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
  base: process.env.BASE_URL,
});

router.beforeEach(async (to, from, next) => {
  let isLogin = localStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresNoAuth)) {
    if (isLogin) {
      next({
        path: "/dashboard",
      });
    } else {
      next();
    }
    return;
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.matched.some((record) => record.meta.dashboard)
  ) {
    // if not, redirect to login page.
    if (isLogin) {
      next();
    } else {
      next({
        path: "/auth/login",
      });
    }
    return;
  }

  next();
});

export default router;
